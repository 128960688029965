:root {



    /* --falcon-bg-navbar-glass: #FCFCFC;
    --ion-background-color: #FCFCFC;
    --falcon-body-bg: #FCFCFC;

    --falcon-navbar-light-brand-color: #FFCC33;
    --falcon-navbar-dark-brand-color: #FFCC33;

    --falcon-blue: #FFCC33;
    --falcon-primary: #FFCC33;


    --ion-tab-bar-color-selected: #FFCC33;
    --ion-tab-bar-color-selected: #FFCC33;
    --falcon-card-gradient: linear-gradient(45deg, #FFCC33, #FFEE55);

    --fc-button-bg-color: #52AE51;
    --fc-button-border-color: #52AE51;

    --falcon-badge-soft-primary-color: white;
    --falcon-badge-soft-primary-background-color: #FFCC33; */
}

/*
.btn-primary,
.navbar-vertical .btn-purchase {
    background-color: #52AE51 !important;
    border-color: #52AE51;
    border-top-color: #52AE51;
    border-right-color: #52AE51;
    border-bottom-color: #52AE51;
    border-left-color: #52AE51;
}

.form-check-input:checked {
    background-color: #52AE51;
    border-color: #52AE51;
}

.theme-wizard .nav-link.active .nav-item-circle {
    background-color: #52AE51 !important;
    color: #fff;
    border-color: #52AE51;
}

.theme-wizard .nav-link.active {
    color: #52AE51;
}

.btn-primary:hover,
.navbar-vertical .btn-purchase:hover {

    background-color: #52AE51;
    border-color: #52AE51;
    border-top-color: #52AE51;
    border-right-color: #52AE51;
    border-bottom-color: #52AE51;
    border-left-color: #52AE51;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-left: 1rem;
}


.btn-primary:disabled,
.navbar-vertical .btn-purchase:disabled,
.btn-primary.disabled,
.navbar-vertical .disabled.btn-purchase {
    color: #fff;
    background-color: #52AE51;
    border-color: #52AE51;
}

button .d-none {
    display: initial !important;
}

.fc .fc-button-primary {
    background-color: #52AE51;
    border-color: #52AE51;
}

.fc .fc-button-primary:hover {
    background-color: #52AE51 !important;
    border-color: #52AE51 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    font-size: 13px !important;
}

.fc .fc-daygrid-day-frame {
    position: relative !important;
    min-height: 100%;
}

ion-app {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

.fc .fc-h-event .fc-event-main {
    font-size: 0.05em !important;
}

.fc-daygrid-day-number {
    pointer-events: none;
}

.fc-event-title {
    font-size: 8px !important;
    white-space: pre-wrap !important;
}

.fc .fc-daygrid-event {
    padding: 3px 2px !important;
}

.finishedMK {
    position: relative;
}

.finishedMK:after {
    position: absolute;
    right: -5px;
    top: -10px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 5px;
    color: #fff;
    background-color: orangered;
    font-size: xx-small;
    border-radius: 20px;
    content: attr(data-badge);
    border: solid 2px orangered;
}


.doctorMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: #fff;
    background-color: #0051ff;
    font-size: xx-small;
    border-radius: 20px;
    content: "医師";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}


.yakuzaisiMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: #fff;
    background-color: #f5803e;
    font-size: xx-small;
    border-radius: 20px;
    content: "薬剤師";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}

.caremaneMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: #fff;
    background-color: #FFCC33;
    font-size: xx-small;
    border-radius: 20px;
    content: "ケアマネ";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}

.nurseMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: black;
    background-color: white;
    font-size: xx-small;
    border-radius: 20px;
    content: "看護師";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}

.rihabiriMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: #fff;
    background-color: #83cad5;
    font-size: xx-small;
    border-radius: 20px;
    content: "リハビリ";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}

.kaigoMK:after {
    position: absolute;
    right: -16px;
    top: -13px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
    padding: 4px;
    color: #fff;
    background-color: #22c83f;
    font-size: xx-small;
    border-radius: 20px;
    content: "介護関係者";
    z-index: 100;
    box-shadow: 0.3px 0.3px 0.3px 0.3px gray;
}






.customfontsize {
    font-size: large !important;
}






.nonedisplay input[type=number]::-webkit-inner-spin-button,
.nonedisplay input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
} */


.avatar .avatar-name>span {

    right: initial;
}

.me-3 {
    margin-left: initial !important;
}

.developing {
    opacity: 0.3!important;
}

body {
    overflow-y: auto !important;
}